<template>
  <v-card v-if="activeProject">
    <v-card-text>
      <v-card-title>Request a List from Campaign Manager</v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              hide-details
              readonly
              dense
              label="Campaign Name"
              v-model="campaignName"
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              hide-details
              readonly
              dense
              label="Agent Name"
              :value="this.$store.state.auth.user.username"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12">
                <v-select
                  hide-details
                  dense
                  mandatory
                  :items="['Singapore', 'Australia']"
                  label="Country"
                ></v-select>
          </v-col>-->
          <v-col cols="12">
            <v-textarea
              hide-details
              outlined
              label="Notes"
              name="notes"
              v-model="note"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="sendRequest" :loading="loading">Request</v-btn>
    </v-card-actions>
  </v-card>
  <v-col v-else-if="activeProject !== null" cols="11">
    <p>Please start on a project first.</p>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      campaignName: "",
      note: "",
      loading: false
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    }
  },
  watch: {
    activeProject: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },
  methods: {
    refresh() {
      if (this.activeProject) {
        this.loading = true;
        this.$axios
          .get("/telemarketer/request_list_info", {
            params: {
              ccmsId: this.activeProject
            }
          })
          .then((res) => {
            this.campaignName = res.data.campaignName;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    sendRequest() {
      this.$axios
        .post("/telemarketer/request_list", {
          note: this.note,
          ccmsId: this.activeProject
        })
        .then((res) => {
          if (res.data) {
            this.note = "";
            this.$store.commit("sendAlert", {
              msg: "Request sent",
              color: "success"
            });
          } else {
            this.$store.commit("sendAlert", {
              msg: "Error occured when trying to send request",
              color: "error"
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};
</script>
